/* Timeline container */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
}
/* Heading */
.experience-heading {
  font-size: 56px;
  font-weight: 400;
}
/* Experience container */
.experience-container {
  display: flex;
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}
/* Timeline item layout */
.timeline-item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 50px;
  position: relative;
}

.timeline-item.right {
  justify-content: flex-end;
}

/* Aligns the date properly with the dot */
.timeline-opposite-content {
  flex: 0 0 150px; /* Fixed width for alignment */
  text-align: right;
  padding-right: 20px;
  color: #999;
  display: flex;
  align-items: center; /* Vertically aligns the date with the logo */
}

/* Timeline separator - logo and line */
.timeline-separator {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
}

.timeline-connector {
  width: 4px;
  background-color: #b3b3b3; /* Updated color for better visibility */
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 1; /* Ensures it stays behind the dots and content */
}

/* Timeline dot (logo) */
.timeline-dot {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  border: 4px solid #4caf50;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Make sure dots are above the connector */
}

.timeline-logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

/* Timeline content */
.timeline-content {
  flex: 3;
  padding: 15px 20px;
}

/* Experience card override */
.experience-card,
.experience-card-dark {
  width: 100%;
}

/* Mobile responsiveness */
@media (max-width: 1380px) {
  .experience-heading {
    font-size: 40px;
  }
}
/* Mobile responsiveness */
@media (max-width: 768px) {
  /* Center-align the entire timeline item */
  .timeline-item {
    flex-direction: column;
    justify-content: center; /* Center the items */
    align-items: center;
    text-align: center;
    margin-bottom: 0px;
    display: flex;
  }

  /* Center-align the date */
  .timeline-opposite-content {
    text-align: center;
    padding: 0;
    margin-bottom: 0px; /* Add space between date and dot */
    display: block;
  }
  .timeline-separator {
    margin: 0 auto;
    align-items: center; /* Center the dot and line */
    display: flex;
  }
  .timeline-connector {
    height: 50px; /* Fixed height to make the vertical line visible */
    display: block;
  }
  .timeline-content {
    padding-left: 0;
    text-align: center;
    display: block;
  }
  .experience-heading {
    font-size: 30px;
    text-align: center;
  }
}
